import React from "react";
import { PlanFilterContext } from '../PlanFilterContext';
import { ListItem, PlanFilterDataContext, withPlanFilterDataContext } from '../PlanFilterDataContext';
import { useParams } from 'react-router';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Card, Divider, Theme, Typography } from '@mui/material';
import { CRCheckbox, CRTooltip } from '@coverright/ui/inputs';
import { CollapseSection, TooltipedTitle } from '@coverright/ui/marketplaces';
import { colors } from '@coverright/ui/themes';
import { planCategories, PlanCategory } from '../../category/mgcategory';
import * as _ from 'lodash';
import { useIsMAState, useIsWIState } from '@coverright/data-access/medicare';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      marginTop: 5
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
      marginTop: 5,
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    inputLabel: {
      fontSize: 14
    },
    input: {
      '& input': {
        padding: '18px 0 14px!important'
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      fontSize: '14px!important',
    },
    radio: {
      padding: '3px 0'
    }
  }),
);

const PlanFilter = (props: {goToCategory: () => void}) => {
  const {zip, countyName} = useParams<{zip: string, countyName: string}>();
  const classes = useStyles();
  const filter = React.useContext(PlanFilterContext)
  const [companies, setCompanies] = React.useState<ListItem[]>([]);
  const [planNames, setPlanNames] = React.useState<ListItem[]>([]);
  const isMassachusetts = useIsMAState(zip);
  const isWisconsin = useIsWIState(zip);

  const {getPlanFilterData, planFilterData} = React.useContext(PlanFilterDataContext);

  React.useEffect(() => {
    if (planFilterData) {
      setCompanies(planFilterData.companies);
      setPlanNames(planFilterData.planNames);
      // window.scrollTo(0, 0)
    }
  }, [planFilterData]);

  React.useEffect(() => {
    if (filter?.values && zip && countyName && filter?.values?.effectiveDate) {
      const filters: any = {...filter?.values, zip, county: countyName};
      delete filters.selectedPlanCategory;
      if (isMassachusetts || isWisconsin) {
        filters.planNames = [];
      }
      getPlanFilterData({variables: {filter: filters}})
    }
  }, [getPlanFilterData, filter?.values, zip, countyName, isMassachusetts, isWisconsin]);

  const plans = React.useMemo(() => {
    let category: PlanCategory | 'all' | undefined;
    if (filter.values?.planNames) {
      const allSelected = _.flatMap(planCategories, c => c.plans).every(plan => filter.values!.planNames!.includes(plan));
      if (allSelected) {
        category = 'all';
      } else {
        const cat = planCategories.find(c => filter.values?.planNames.every(p => c.plans.includes(p)))
        if (cat) {
          category = cat.name;
        } else {
          category = filter.values?.selectedPlanCategory;
        }
      }
    } else {
      category = filter.values?.selectedPlanCategory;
    }
    return category ?
      category === 'all' ?
        planNames.filter(p => planCategories.flatMap(cat => cat.plans).includes(p.value)) :
        planNames.filter(p => planCategories.find(cat => cat.name === category)!.plans.includes(p.value)) :
      planNames.filter(p => planCategories.find(cat => cat.name === PlanCategory.MostPopular)!.plans.includes(p.value))
  }, [filter.values, planNames])


  if (!filter.values) {
    return null;
  }

  const Category = () => <div>
    <Typography color={'textPrimary'} className={'fs-14 medium'}>Category selected: <b className={'dark-green'}>
      {filter?.values?.selectedPlanCategory === 'all' ? 'All plans' : filter?.values?.selectedPlanCategory}
    </b></Typography>
  </div>

  return <Card sx={{p: 3, minWidth: 260}} className={'plan-filters'}>
    <Box marginBottom={'16px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography className={'fs-18 lh-22 bold'}>Filter your results</Typography>
      <Typography variant={'body1'} sx={{color: colors.custom.green.variant2}} className={classes.link} onClick={filter.reset}>Reset filters</Typography>
    </Box>
    {(isMassachusetts === false && isWisconsin === false) && <>
      <Box>
        <FilterItemWithCheckbox items={plans}
                                selectedKeys={filter.values.planNames || []}
                                onChange={val => filter.switchPlan(val)}
                                tooltip={'Medicare Supplement plans are federally standardized. Plan G from ‘Company A’ provides the same exact coverage as Plan G from ‘Company B’. Once you have selected a specific plan type, the only difference across insurance companies is price.'}
                                additionalContent={<div>
                                  <Category />
                                  <Typography sx={{color: colors.custom.green.variant5}} className={'medium fs-14 lh-15 mv-8'}>
                                    <strong>Not sure?</strong> <span onClick={props.goToCategory} className={'underline pointer'} >Click here</span> to find out.
                                  </Typography>
                                </div>}
                                title={'Plan type'}/>
      </Box>
      <Divider sx={{my: '20px', borderColor: '#E8ECED'}}/>
    </>}
    <Box>
      <FilterItemWithCheckbox items={companies}
                              selectedKeys={filter.values.companies || []}
                              onChange={val => {
                                filter.switchCompanies(val)
                              }}
                              title={'Company'}/>
    </Box>

  </Card>
}

export default withPlanFilterDataContext(PlanFilter);

interface FilterItemWithCheckboxProps {
  items?: ListItem[],
  selectedKeys: string[],
  onChange: (key: any) => void,
  title: string,
  additionalContent?: JSX.Element,
  tooltip?: string
}

function FilterItemWithCheckbox({items, selectedKeys, onChange, title, tooltip, additionalContent}: FilterItemWithCheckboxProps) {
  const classes = useStyles();

  return <CollapseSection title={<TooltipedTitle className={'fs-16 lh-20 medium'} title={title} tooltip={tooltip}/>}>

    {additionalContent}

    {!!items && items.map(item => <CRTooltip key={item.value} placement={'top'} arrow title={item.tooltip || ''}>
      <div>
        <CRCheckbox variant={'standart'}
                        key={item.value}
                        name={item.value}
                        labelClassName={classes.checkboxLabel}
                        controlClassName={classes.checkbox}
                        label={item.label}
                        checked={!!selectedKeys && selectedKeys.includes(item.value)}
                        disabled={item.disabled && !selectedKeys.includes(item.value)}
                        onChange={() => {
                          onChange(item.value)
                        }}
        />
      </div>
    </CRTooltip>)
    }
  </CollapseSection>
}
